import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { experimentedPages } from 'experiment.config';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { z } from 'zod';

import { Button, Flex, FormControl, FormLabel, IconButton, Input, useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';

import { Glyph } from '~/components/glyph';
import { Modal } from '~/components/modal';

const experimentConfigSchema = z.object({
  flagKey: z
    .string()
    .trim()
    .nonempty()
    .refine((val) => !val.includes(' '), {
      message: 'Flag key must not contain spaces.',
    }),
  variant: z
    .string()
    .trim()
    .nonempty()
    .refine((val) => !val.includes(' '), {
      message: 'Flag key must not contain spaces.',
    }),
});

type ExperimentConfig = z.infer<typeof experimentConfigSchema>;

export function ExperimentSettingsButton() {
  const router = useRouter();
  const [isActionButtonVisible, setIsActionButtonVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { register, handleSubmit } = useForm<ExperimentConfig>({
    resolver: zodResolver(experimentConfigSchema),
  });
  const { isOpen, onClose, onOpen } = useDisclosure();
  const queryClient = useQueryClient();
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (router.query.experimentSettings === 'open') {
      setIsActionButtonVisible(true);
    }
  }, [router.query.experimentSettings]);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (/^e$/i.test(event.key) && event.ctrlKey && event.shiftKey) {
        setIsActionButtonVisible((prev) => !prev);
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  function submit({ flagKey, variant }: { flagKey: string; variant: string }) {
    setIsSubmitting(true);
    const ssrExperimentConfig = experimentedPages.find((config) => config.experimentKey === flagKey);
    if (ssrExperimentConfig) {
      const url = new URL(window.location.href);
      if (ssrExperimentConfig.pathRegex.test(url.pathname)) {
        url.searchParams.set('forcedVariation', variant);
        window.location.href = url.toString();
      } else {
        setIsSubmitting(false);
        alert(
          'The current page does not match the experiment configuration. Make sure to navigate to the correct page before setting the experiment variant.'
        );
      }
    } else {
      queryClient.setQueryData(['clientExperiment', flagKey], variant);
      onClose();
      setIsSubmitting(false);
      setIsActionButtonVisible(false);
    }
  }

  return (
    <>
      <IconButton
        ref={buttonRef}
        aria-label='Experiment Settings'
        border='4px solid'
        borderColor='accent.lime'
        bottom='12px'
        colorScheme='sea-foam'
        height='64px'
        icon={<Glyph height={32} name='gear-beaker-one' width={32} />}
        isDisabled={!isActionButtonVisible}
        left='12px'
        position='fixed'
        size='icon'
        transition='transform 250ms ease-in-out'
        width='64px'
        _hover={{
          bg: 'accent.sea-foam.600',
        }}
        transform={
          isActionButtonVisible
            ? 'translateX(0px) scale(1) rotate(0deg)'
            : 'translateX(-76px) scale(0.1) rotate(-180deg)'
        }
        isRound
        onClick={onOpen}
      />
      <Modal initialFocusRef={buttonRef} isOpen={isOpen} size='xl' title='Set Experiment Variant' onClose={onClose}>
        <Flex as='form' flexDir='column' gap={4} onSubmit={handleSubmit(submit)}>
          <Flex gap={4}>
            <FormControl>
              <FormLabel mb={2}>Experiment Key</FormLabel>
              <Input type='text' {...register('flagKey')} placeholder='ab_flag_key_here' />
            </FormControl>
            <FormControl>
              <FormLabel mb={2}>Variant</FormLabel>
              <Input type='text' {...register('variant')} placeholder='variant_x' />
            </FormControl>
          </Flex>
          <Button isDisabled={isSubmitting} isLoading={isSubmitting} type='submit'>
            Submit
          </Button>
        </Flex>
      </Modal>
    </>
  );
}
